export const transcribeData = {
  h1: "Lightning fast video captions for progressive teams",
  subheading: "Upload an original video to get a transcript and a final video with the burned-on captions",
  body: "We use the latest transcription models from Open AI to get you the fastest, most accurate automated video captions",
  exampleFile: "https://audio-samples.github.io/samples/mp3/blizzard_biased/sample-0.mp3",
  videoFileHint: "Drop mp4 video to add captions",
  footer: "Terms and conditions | FAQs | Contact us",
  samplePublicId: "transcribe/qsinto8j97q92awyevw0",
  sampleVideo: "https://res.cloudinary.com/yeti-ai/video/upload/v1664862981/transcribe/qsinto8j97q92awyevw0.mp4",
  sampleAudio: "https://res.cloudinary.com/yeti-ai/video/upload/v1664862981/transcribe/qsinto8j97q92awyevw0.mp3",
  samplePoster: "https://res.cloudinary.com/yeti-ai/video/upload/v1664862981/transcribe/qsinto8j97q92awyevw0.jpg",
  storageBase: "https://api.cloudinary.com/v1_1/yeti-ai/",
  transcribeBase: "https://transcribe-py-nvfwt3ycma-uc.a.run.app"
}
