<!-- eslint-disable camelcase -->
<template>
  <v-container fluid>
    <v-row justify-start>
      <v-col>
        <h1 class="h1 mb-2">{{ h1 }}</h1>
        <div class="subheading raleway-medium-black-18px mb-4">{{ subheading }}</div>
        <div class="subheading raleway-medium-black-16px">{{ body }}</div>
      </v-col>
    </v-row>
    <v-row justify-start>
      <v-col lg="5" md="4" sm="12" xs="12" class="left">
        <v-row>
          <v-col>
            <h3>Original video</h3>
            <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"
            v-on:vdropzone-sending="onSending"
            v-on:vdropzone-success="onSuccess"
            class = "mt-4 mb-2"
            ></vue-dropzone>
            <div><a @click="setVideoToExample">👉 Load example file</a></div>
            <video class="videoPreview mt-2 mb-4" id="initialVideo" :src="form.video_file" :poster="form.poster" type="video/mp4" controls></video>
            <form class="form">
              <div class="label">Transcript quality:</div>
              <v-select :items = "qualities" item-text="label" item-value="value" v-model="form.quality"></v-select>
            </form>
          </v-col>
        </v-row>
        <v-row justify-end>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn
              class="button gigas"
              @click="generateTranscript"
              x-large
              depressed
            >Generate Transcript 👀</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
      <v-col lg="5" md="6" sm="12" xs="12">
        <v-row v-if="error">
          <v-col class="text-center mid-width">
            <v-alert
              color="red"
              type="error"
            >{{errorMessage}}</v-alert>
          </v-col>
        </v-row>
        <v-row v-if="waiting">
          <v-col class="text-center mid-width">
            <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              class="progress"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="h3">Transcript</h3>
            <div><p>Fix any typos here:</p></div>
            <v-textarea
              v-model="transcript"
              outlined
              dense
              background-color="#D9D9D9"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify-end>
          <v-col>
            <v-spacer></v-spacer>
            <v-btn outlined class="button" x-large depressed @click="downloadTranscript()" :disabled="!transcriptReady">Download Transcript</v-btn>&nbsp;&nbsp;
            <v-btn primary class="button gigas" x-large depressed @click="generateFinalVideo" :disabled="!transcriptReady">Generate Final Video ✨</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
    </v-row>
    <v-row justify-start>
      <v-col>
        <h3 class="mt-5">Final video</h3>
        <video class="finalVideoPreview mt-4 mb-4" id="finalVideo" :src="finalVideo" :poster="form.poster" type="video/mp4" controls></video>
      </v-col>
    </v-row>
    <v-row justify-start>
      <v-col>
        <v-btn outlined class="button" x-large depressed @click="downloadFinalVideo" :disabled="!finalVidReady">Download Final Video</v-btn>&nbsp;&nbsp;
      </v-col>
    </v-row>
    <v-row>
          <v-col>
            <div class="footer" id="footer">{{ footer }} </div>
          </v-col>
        </v-row>
  </v-container>
</template>

<script>
import axios from "axios"
import { saveAs } from "file-saver"
import vue2Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import confetti from "canvas-confetti"

export default {
  name: "TranscriptEditor",
  components: {
    vueDropzone: vue2Dropzone
  },
  props: [
    "h1",
    "subheading",
    "body",
    "exampleFile",
    "videoFileHint",
    "footer",
    "samplePublicId",
    "sampleVideo",
    "sampleAudio",
    "samplePoster",
    "storageBase",
    "transcribeBase"
  ],
  data: () => ({
    dropzoneOptions: {
      url: "https://api.cloudinary.com/v1_1/yeti-ai/video/upload",
      thumbnailWidth: 150,
      maxFilesize: 100,
      maxFiles: 1
    },
    form: {
      publicId: "",
      video_file: "",
      audio_file: "",
      poster: "",
      quality: "base",
      format: "srt"
    },
    formats: [{ label: "SRT", value: "srt" }, { label: "VTT", value: "vtt" }],
    qualities: [{ label: "Medium - faster", value: "base" }, { label: "High - slower", value: "medium" }],
    waiting: false,
    transcriptReady: false,
    finalVidReady: false,
    error: false,
    errorMessage: "",
    initialVideo: null,
    transcript: "",
    transcriptBlob: null,
    finalVideo: null
  }),
  methods: {
    setVideoToExample () {
      this.form.publicId = this.samplePublicId
      this.form.video_file = this.sampleVideo
      this.form.audio_file = this.sampleAudio
      this.form.poster = this.samplePoster
    },
    uploadFileToCloudinary () {
      this.waiting = true
      const formData = new FormData()
      formData.append("file", this.transcriptBlob)
      formData.append("upload_preset", "transcript")

      const transcriptFileName = this.form.publicId.split("/")[1] + "." + this.form.format
      formData.append("public_id", transcriptFileName)
      axios.post(this.storageBase + "raw/upload", formData)
        .then(response => {
          console.log(response)
          const transcriptPublicId = response.data.public_id
          this.finalVideo = "https://res.cloudinary.com/yeti-ai/video/upload/l_subtitles:" + transcriptPublicId + "/" + this.form.publicId + ".mp4"
          setTimeout(() => { this.finalVidReady = true }, 3000)
          confetti()
        })
        .catch(error => {
          this.error = true
          this.errorMessage = "Error uploading file"
          setTimeout(() => { this.error = false }, 2000)
          console.log(error)
        })
        .finally(() => {
          this.waiting = false
          console.log("Done")
        })
    },
    onSending (file, xhr, formData) {
      formData.append("upload_preset", "transcribe")
    },
    onSuccess (file, response) {
      console.log(response)
      const { secure_url, public_id } = response
      const baseUrl = secure_url.substring(0, secure_url.length - 4)

      this.form.publicId = public_id
      this.form.video_file = secure_url
      this.form.audio_file = baseUrl + ".mp3"
      this.form.poster = baseUrl + ".jpg"
      this.generateTranscript()
    },
    generateTranscript () {
      console.log(this.form)
      this.waiting = true
      axios.post(this.transcribeBase, this.form)
        .then((res) => {
          // Perform Success Action
          if (res.data) {
            console.log(res.data)
            this.transcript = res.data.subtitles
            this.transcriptReady = true
            confetti()
          }
        })
        .catch((error) => {
          // error.response.status Check status code
          this.error = true
          this.errorMessage = (error.message + ". Please try again soon.")
          setTimeout(() => { this.error = false }, 2000)
          console.log(error)
        }).finally(() => {
          // Perform action in always
          this.waiting = false
          console.log("Done")
        })
    },
    downloadTranscript () {
      console.log("Download transcript")
      this.transcriptBlob = new Blob([this.transcript], { type: "text/plain;charset=utf-8" })
      saveAs(this.transcriptBlob, "transcript." + this.form.format)
    },
    generateFinalVideo () {
      console.log("Generate final video")
      this.transcriptBlob = new Blob([this.transcript], { type: "text/plain;charset=utf-8" })
      this.uploadFileToCloudinary()
    },
    downloadFinalVideo () {
      console.log("Download final video")
      const fileName = this.form.publicId.split("/")[1] + ".mp4"
      saveAs(this.finalVideo, fileName)
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../variables'
@import '~vuetify/src/styles/settings/_variables'

h3
  font-family: Raleway
  font-style: normal
  font-weight: bold
  font-size: 24px
  line-height: 29px

.left
  background-color: orange
  border-radius: 0 90px 0 0
  box-shadow: 0px 4px 20px 5px #00000040

.h1
  font-family: $font-family-raleway
  font-size: $font-size-l
  font-weight: 700
  margin: auto 60px 0 0

.form
  align-items: flex-start
  border: 0.91px none
  display: flex
  flex-direction: column
  gap: 18.29px
  position: relative

.v-text-field
  min-width: 300px

.label
  font-weight: 400
  height: 14px
.radio-group
  margin-top: 0
  height: 32px
.gigas
  background-color: $gigas!important
  color: $white

.footer
  font-family: $font-family-raleway
  font-size: $font-size-s
  font-style: italic
  font-weight: 100
  margin-top: 20px

.nographics
  color: #5A3092
  font-size: 34px
  margin-top: 100px
  max-width: 500px

.progress
  margin-bottom:40px

.mid-width
  max-width: 500px

.videoPreview
  background-color: #D9D9D9
  border-radius: 5px
  width: 400px
  height: 300px

.finalVideoPreview
  background-color: #D9D9D9
  border-radius: 5px
  width: 600px
  height: 450px

.dropzone
  background-color: #D9D9D9
  border-radius: 5px
  width: 400px

@media #{map-get($display-breakpoints, 'sm-and-down')}
  .videoPreview
    width: 300px
    height: 225px

  .finalVideoPreview
    width: 300px
    height: 225px
  .dropzone
    width: 300px

</style>
