<template>
  <v-app>
    <v-app-bar flat color="white">
      <v-toolbar-title class="app-bar-text">Transcripts & Captions (Beta)</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn text to="/copy" class="no-uppercase">Copy</v-btn>
      <v-btn text to="/graphics" class="no-uppercase">Graphics</v-btn> -->
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App"
}
</script>

<style lang="sass">
  .v-main
    height: 100%

  .app-bar-text
    color: black
    font-weight: 800

  .no-uppercase
    text-transform: unset !important
</style>
