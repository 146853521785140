import Vue from "vue"
import Router from "vue-router"
import TranscriptEditor from "./components/TranscriptEditor"
import { transcribeData } from "./transcribeData"

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: TranscriptEditor,
      props: { ...transcribeData }
    }
  ]
})